<form [formGroup]="mForm">
  <div>
    <div>
      <label>
        {{"inviteUser.company"|cxTranslate}}
      </label>
    </div>
    <div>
      <label>
        <input type="radio" value="user" formControlName="userCompany">
        {{"inviteUser.UserIsEmployed"|cxTranslate}}
      </label>
    </div>
    <div>
      <label>
        <input type="radio" value="outer" formControlName="innerCompany">
        {{"inviteUser.UserIsEmployedAnotherCompany"|cxTranslate}}
      </label>
    </div>
  </div>
</form>